
@media screen and (min-width: 992px){
  .Header{
    background-color: #cec8ba;
    height: 100%;

  }
  .Pages{
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    .Page{
      font-family: 'Noto Sans Multani', sans-serif;
      font-size: 90%;
      font-family: "Ladyrose";
      cursor: pointer;
      color: black;
      text-decoration: none;
      letter-spacing: 1.5px;
    }
    .Pages_Img{
      width: 10%;
      height: 100%;
      margin-top: 1%;
    }
}   
}

@media screen and (max-width:768px) {

        .Header{
            background-color: #cec8ba;
            height: 100%;

          }
          .Pages{
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;

            .Page{
              font-family: 'Noto Sans Multani', sans-serif;
              font-size: 60%;
              font-family: "Ladyrose";
              cursor: pointer;
              color: black;
              text-decoration: none;
            }
            .Pages_Img{
              width: 15%;
              height: 90%;
              margin-top: 1%;
            }
        }   
    }
   





