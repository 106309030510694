@media screen and (min-width: 992px){
  .Aboutpage{
    background-color: #E4E1D8;
    .Aboutpage_Header{
      height: 8vh;
    }
    .Aboutpage_About1{
      height: 120vh;
      width: 100%;
      padding-top: 7%;
      position: relative;
      display: flex;
      flex-direction: column;
      .Aboutpage_About1_Bg{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      z-index: 0;
      }
      .Aboutpage_About_Title{
        display: flex;
        flex-direction: row;
        height: 40%;
        margin-left: 12%;

        .Aboutpage_About_Title1{
          font-size: 350%;
          height: 100%;
          width: 35%;
          font-family: "Ladyrose";

          position: relative;
          text-align: right;
          letter-spacing: 2px;
          z-index: 1;
          display: flex;
          justify-content: end;
          align-items: end;
          padding-bottom: 12px;
        }
        .Aboutpage_About_Title2{
          height: 100%;
          width: 25%;
          display: flex;

          align-items: end;
          background-image:url('../assets/imgBrother/mybrother8.webp');
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: cover;
          font-size: 350%;
          font-family: "Ladyrose";
          margin-left: 1%;
          padding-left: 1%;

          color: #E4E1D8;
          letter-spacing: 2px;
          z-index: 1;
          border-right: 12px solid #E4E1D8;
          border-bottom: 12px solid #E4E1D8;
        }
        .Aboutpage_About_Title_Img{
          position: absolute;
          top: 35%;
          right: -130%;
          height: 110%;
          width: 80%;
          background-image:url('../assets/imgBrother/mybrother7.webp');
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: cover;
          z-index: 0;
        }
      }
      .Aboutpage_About_Text{
        width: 35%;
        height: 30%;
        line-height: 20px;
        letter-spacing: 0.5px;
        z-index: 1;
        text-align: right;
        position: absolute;

        top: 50%;
        left: 18%;
        .Aboutpage_About_Text_Text{
          font-family: "SemiBold15";
          font-size: 90%;
          height: 30%;
          z-index: 1;
        }
        
      }
    
    }
    .Aboutpage_About2{
      display: flex;
      flex-direction: column;
      height: 70vh;
      width: 100%;
      background-color: #2d2e2d;
      align-items: center;
      position: relative;
      .Aboutpage_About2_Bg{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 0;
      }
      .Aboutpage_About2_Img{
        position: absolute;
        height: 80%;
        width: 70%;
        bottom: 37%;
        left: 19%;
        background-color: #E4E1D8;
      }
      .Aboutpage_About2_Text_Img_Img{
        position: absolute;
        height: 110%;
        width: 75%;
        bottom: 40%;
        background-image:url('../assets/imgBrother/imgBrother8.webp');
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    .Aboutpage_About3{
      display: flex;
      height: 40vh;
      width: 100%;
      background-color: #E4E1D8;
      align-items: center;
      justify-content: end;
      position: relative;

      .Aboutpage_About3_Bg{
        position: absolute;
        width: 100%;
        height: 100%;
      }
.Aboutpage_About3_Img_bg{
        position: absolute;
        height: 111%;
        width: 23%;
         background-color: #E4E1D8;
          bottom: 35%;
          left: 12.5%;
}
        .Aboutpage_About3_Img{
          position: absolute;
          height: 110%;
          width: 23%;
          background-image:url('../assets/imgBrother/imgBrother9.webp');
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: cover;
          bottom: 31%;
          left: 14%;
        }
        .Aboutpage_About3_Text_Container{
          position: absolute;
          bottom: 25%;
          right: 8%;
          width: 80%;
          height: 50%;
          display: flex;
          flex-direction: column;
          align-items: end;
          justify-content: space-evenly;

          .Aboutpage_About3_Text{
            width: 60%;
            font-size: 90%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: left;
            font-family: "SemiBold15";
            line-height: 20px;
            font-size: 90%;
            letter-spacing: 0.8px;
          }
        }

    }
    
    .Aboutpage_Footer{
      height: 25vh;
    }
  }
}
@media screen and (max-width:768px) {
  .Aboutpage{
    background-color: #E4E1D8;
    .Aboutpage_Header{
      height: 5vh;
    }
    .Aboutpage_About1{
      height: 60vh;
      width: 100%;
      padding-top: 5%;
    position: relative;
    .Aboutpage_About1_Bg
    {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      z-index: 0;
    }
      .Aboutpage_About_Title{
        display: flex;
        flex-direction: row;
        height: 70%;
        margin-left: 12%;
        .Aboutpage_About_Title1{
          font-size: 180%;
          height: 100%;
          width: 40%;
          font-family: "Ladyrose";
          padding-top: 4%;
          position: relative;
          text-align: right;
          letter-spacing: 2px;
          z-index: 1;
        }
        .Aboutpage_About_Title2{
          height: 100%;
          width: 60%;
          background-image:url('../assets/imgBrother/mybrother7.webp');
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: cover;
          font-size: 165%;
          font-family: "Ladyrose";
          margin-left: 3%;
          padding-left: 3%;
          padding-top: 4%;

          color: #E4E1D8;
          letter-spacing: 2px;
          z-index: 0;
        }
        .Aboutpage_About_Title_Img{
          position: absolute;
          top: 20.5%;
          left: 10%;
          height: 65%;
          width: 130%;
          background-image:url('../assets/imgBrother/mybrother8.webp');
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: cover;
          border: 12px solid #E4E1D8;
          z-index: 1;
        }
      }
      .Aboutpage_About_Text{
        width: 80%;
        height:20%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-left: 12%;
        margin-top: 7%;
        line-height: 15px;
        letter-spacing: 0.5px;
        .Aboutpage_About_Text1{
          font-family: "SemiBold15";
          font-size: 65%;
          z-index: 1;
        }
        .Aboutpage_About_Text2{
          width: 100%;
          font-family: "SemiBold15";
          font-size: 65%;
          z-index: 1;
        }
      }
    
    }
    .Aboutpage_About2{
      display: flex;
      flex-direction: column;
      height: 50vh;
      width: 100%;
      background-color: #2d2e2d;
      align-items: center;

      .Aboutpage_About2_Img{
        height: 70%;
        width: 100%;
        background-image:url('../assets/imgBrother/myBrother6.webp');
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .Aboutpage_About2_Text{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30%;
        width: 100%;
        position: relative;

        .Aboutpage_About2_Text_Bg{
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 0;
        }
        .Aboutpage_About2_Text_Content{
          color: white;
          font-family: "SemiBold15";
          font-size: 60%;
          text-align: left;
          width: 80%;
          line-height: 13px;
          letter-spacing: 0.5px;
          z-index: 1;
        }
      }
    }
    .Aboutpage_About3{
      display: flex;
      flex-direction: row;
      height: 30vh;
      width: 100%;
      background-color: #E4E1D8;
      align-items: center;
      position: relative;
      .Aboutpage_About3_Bg{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
      }
      .Aboutpage_About3_Img{
        height: 50%;
        width: 50%;
        background-image:url('../assets/imgBrother/imgBrother8.webp');
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        border-top: 10px solid #2d2e2d;
        border-bottom: 10px solid #2d2e2d;
        z-index: 1;
      }
      .Aboutpage_About3_Text{
        display: flex;

        align-items: center;
        height: 100%;
        width: 50%;
        .Aboutpage_About3_Text_Content{
          color: #2d2e2d;
          font-family: "SemiBold15";
          font-size: 67%;

          text-align: left;
          width: 75%;
          line-height: 13px;
          padding-left: 10%;
          letter-spacing: 0.5px;
          z-index: 1;
        }
      }
    }
    .Aboutpage_About4{
      display: flex;
      flex-direction: row;
      height: 30vh;
      width: 100%;
      background-color: #2d2e2d;
      align-items: center;
     position: relative;
     .Aboutpage_About4_Bg{
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
     }
      .Aboutpage_About4_Img{
        height: 70%;
        width: 30%;
        background-image:url('../assets/imgBrother/imgBrother9.webp');
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
      }
      .Aboutpage_About4_Text{
        display: flex;
        justify-content: end;
        align-items: center;
        height: 100%;
        width: 55%;
        .Aboutpage_About4_Text_Content{
          color: #E4E1D8;
          font-family: "SemiBold15";
          font-size: 60%;
          text-align: right;
          width: 75%;
          line-height: 13px;
          padding-right: 10%;
          letter-spacing: 0.5px;
          z-index: 1;
        }
      }
    }
    .Aboutpage_Footer{
      height: 25vh;
    }
  }
}