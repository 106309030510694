@media screen and (min-width: 992px){
  .ContactPage{
  .ContactPage_Content{
    position: relative;
    background-color:#E4E1D8 ;
    height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: -1;
    .ContactPage_Header{
      height: 8vh;
    }
    .ContactPage_Contact_Bg{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      z-index: 0;
    }
    .ContactPage_Title{
      width: 45%;
      height: 15%;
      font-family: "Ladyrose";
      color:#2d2e2d;
      display: flex;
      align-items: center;
      font-size: 250%;
      letter-spacing: 5px;
      z-index: 3;
      margin-top: 4%;


    }
    .ContactPage_Text{
      width: 70%;
      height: 10%;
      font-size: 120%;
      font-family: "Ladyrose";
      color:#b38632;
      display: flex;
      margin-left: 25%;
      align-items: center;
      z-index: 3;
    }
    .ContactPage_Map{
      height: 45%;
      width: 100%;
      z-index: 3;


      .ContactPage_Map_Size{
        margin-left: 27.5%;
        height: 100%;
        width: 42.5%;
        border: none;
        z-index: 3;
      }
    }
    .ContactPage_Contact_Content{
      height: 10%;
      width: 45%;
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: auto auto;
      margin-top: 1%;
      .ContactPage_Contact_Content_Text{
        color: #2d2e2d;
        font-family: "Medium15";
        display: flex;

        align-items: center;
        letter-spacing: 2px;
        font-size: 100%;
        z-index: 1;
      }
    }
  }
  .ContactPage_Footer{
    height: 25vh;

  }
}
}

@media screen and (max-width: 700px){
  .ContactPage{
    .ContactPage_Header{
      height: 5vh;
    }
    .ContactPage_Background{
      height: 35vh;
      background-image:url('../assets/imgBrother/myBrother6.webp');
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: cover;
    }
    .ContactPage_Content{
      height: 40vh;
      width: 100%;
      background-color: black;
      background-image:url('../assets/bg/bg1/contact/10.webp');
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 10%;
      .ContactPage_Title{
        height: 15%;
        font-size: 180%;
        font-family: "Ladyrose";
        color:#b38632;
        display: flex;
        align-items: end;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 8%;
        letter-spacing: 5px;

      }
      .ContactPage_Content_Content{
        height: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        .ContactPage_Content_Text{
          color: #E4E1D8;
          font-family: "Medium15";
          margin-top: 8%;
          letter-spacing: 2px;
          font-size: 80%;
        }
       
    }
  }
    .ContactPage_Footer{
      height: 25vh;
    }
  }

}