

@media screen and (min-width: 992px){
  .Awardspage{
    background-color: #E4E1D8;
    .Awardspage_Header{
      height: 8vh;
      
    }
    .Awardspage_Achive{
      position: relative;
      height: 100vh;
      width: 100%;
      background-color: white;
      padding-top: 5%;
      padding-bottom: 5%;
      z-index: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
     .Awardspage_Achive_Bg{
      position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 0;
     }
      .Awardspage_Achive_Title{
        height: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;


        .Awardspage_Achive_Title1{
          font-size: 250%;
          font-family: "Ladyrose";
          letter-spacing: 2px;
          z-index: 1;
          height:35%;
        }
          .Awardspage_Achive_Title2{
            height: 30%;
            font-size: 250%;
            font-family: "Ladyrose";
            letter-spacing: 2px;
            z-index: 1;
          }

      }
      .Awardspage_Achive_Text{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45%;
        height: 20%;
        margin-bottom: 6%;
        letter-spacing: 0.5px;
        background-color: #2d2e2d;
        border-bottom-right-radius: 50px;
        border-top-left-radius: 50px;
        z-index: 3;
        .Awardspage_Achive_Text_Content{
          width: 80%;
          font-family: "SemiBold15";
          font-size: 110%;
          text-align: center;
          line-height: 25px;
          z-index: 3;
          color: #E4E1D8;
        }
      }
      .Awardspage_Achive_Achive{
        height: 50%;
        width: 100%;
        
        .Awardspage_Achive_Achive_Content{
          height: 70%;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          
          .Awardspage_Achive_Achive_Convention{
            width: 20vw;
            height: 20vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;

            z-index: 1;
            .Awardspage_Achive_Achive_Convention_Img_Container{
              width: 35%;
              height: 35%;
              border-radius: 50%;
              background-color: #E4E1D8;
              box-shadow: 5px 5px 10px 1px grey;
              display: flex;
              justify-content: center;
              align-items: center;
              .Awardspage_Achive_Achive_Convention_Img{
                width: 80%;
                height: 43%;
              }
            }
            .Awardspage_Achive_Achive_Convention_Text{
              width: 50%;
              height: 10%;
              font-family: "Ladyrose";
              text-align: center;
              font-size: 100%;
              line-height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
            }
            .Awardspage_Achive_Achive_Convention_Text_Detail{
              width: 60%;
              height: 15%;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              font-family: "Light15";
              font-size: 90%;
              line-height: 20px;
            }
          }
          .Awardspage_Achive_Achive_YrsExper{
            width: 20vw;
            height: 20vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;

            z-index: 1;
            .Awardspage_Achive_Achive_YrsExper_Img_Container{
              width: 35%;
              height: 35%;
              border-radius: 50%;
              background-color: #E4E1D8;
              box-shadow: 5px 5px 10px 1px grey;
              display: flex;
              justify-content: center;
              align-items: center;
              .Awardspage_Achive_Achive_YrsExper_Img{
                width: 35%;
                height: 50%;
              }
            }
           
            .Awardspage_Achive_Achive_YrsExper_Text{
              width: 50%;
              height: 10%;
              font-family: "Ladyrose";
              text-align: center;
              font-size: 100%;
              line-height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
            }
            .Awardspage_Achive_Achive_YrsExper_Text_Detail
            {
              width: 80%;
              height: 15%;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              font-family: "Light15";
              font-size: 90%;
              line-height: 20px;
            }
          }
          .Awardspage_Achive_Achive_Trophies{
            width: 20vw;
            height: 20vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;

            z-index: 1;
            .Awardspage_Achive_Achive_Trophies_Img_Container{
              width: 35%;
              height: 35%;
              border-radius: 50%;
              background-color: #E4E1D8;
              box-shadow: 5px 5px 10px 1px grey;
              display: flex;
              justify-content: center;
              align-items: center;
              .Awardspage_Achive_Achive_Trophies_Img{
                width: 80%;
                height: 43%;
              }
            }
            
            .Awardspage_Achive_Achive_Trophies_Text{
              width: 50%;
              height: 10%;
              font-family: "Ladyrose";
              text-align: center;
              font-size: 100%;
              line-height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
            }
            .Awardspage_Achive_Achive_Trophies_Text_Detail
            {
              width: 80%;
              height: 15%;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              font-family: "Light15";
              font-size: 90%;
              line-height: 20px;
            }
          }
        }
        
      }
    }
    .Awards_Seperate1{
      height: 5vh;
      width: 100%;
      background-color: #2d2e2d;
    }
    .Awards_Awards{
      position: relative;
      height: 60vh;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

    .Awards_Awards_Bg{
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
      
      .Awards_Awards_Competition_Logo{
        height: 80%;
        width: 50%;
        display: grid;
        grid-template-columns: 8% 8% 8% 8% 8% 8% 8% 8% 8% 8% 8% 8%;
        grid-template-rows: 1fr 1fr 1fr;

        align-items: center;
        justify-content: center;
        column-gap: 2%;
        z-index: 1;
        .Awards_Awards_Competition_Logo_Container1{
          width: 60%;
          height: 45%;
          grid-column: span 3;
          margin: auto;
          .Competition_Logo1{
            width: 100%;
            height: 100%;
    
          }
        }
        .Awards_Awards_Competition_Logo_Container2{
          margin: auto;
          width: 45%;
          height: 65%;
          grid-column: span 3;
          .Competition_Logo2{
            width: 100%;
            height: 100%;
          }
        }
        .Awards_Awards_Competition_Logo_Container3{
          margin: auto;
          width: 50%;
          height: 60%;
          grid-column: span 3;
          .Competition_Logo3{
            width: 100%;
              height: 100%;
          }
        }
        .Awards_Awards_Competition_Logo_Container4{
          margin: auto;
          width: 35%;
          height: 45%;
          grid-column: span 3;
          .Competition_Logo4{
            width: 100%;
            height: 100%;
          }
        }
      .Awards_Awards_Competition_Logo_Container5{
        margin: auto;
        width: 70%;
        height: 50%;
        grid-column: span 3;
        background-color: black;
        .Competition_Logo5{
          width: 100%;
          height: 100%;
        }
      }
        .Awards_Awards_Competition_Logo_Container6{
          margin: auto;
          width: 50%;
          height: 70%;
          grid-column: span 3;
          .Competition_Logo6{
            width: 100%;
            height: 100%;}
        }
        .Awards_Awards_Competition_Logo_Container7{
          margin: auto;
          width: 70%;
          height: 50%;
          grid-column: span 3;
          .Competition_Logo7{
            width: 100%;
              height: 100%;
          }
        }
      .Awards_Awards_Competition_Logo_Container8{
      margin: auto;
          width: 60%;
          height: 75%;
          grid-column: span 3;
          .Competition_Logo8{
            width: 100%;
                height: 100%;
          }
}
       .Awards_Awards_Competition_Logo_Container9{
        margin: auto;
        width: 60%;
        height: 30%;
        grid-column: span 4;
        .Competition_Logo9{
          width: 100%;
          height: 100%;
        }
       }
       .Awards_Awards_Competition_Logo_Container10{
        width: 80%;
        height: 50%;
        grid-column: span 4;
        margin: auto;
        .Competition_Logo10{
          width: 100%;
          height: 100%;
        }
       }
       .Awards_Awards_Competition_Logo_Container11{
        width: 80%;
        height: 60%;
        grid-column: span 4;
        margin: auto;
        .Competition_Logo11{
          width: 100%;
          height: 100%;
        }
       }
      }
    }
    .Awardspage_List{
      height: 110vh;
      width: 100%;
      background-color: #2d2e2d;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      position: relative;
      .Awardspage_List_Bg{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
      }
      .Awardspage_List_Title{
        height: 20%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        .Awardspage_List_Title_Title1{
          width: 30%;
          height: 35%;
          margin-right: 30%;
          z-index: 3;
          .Awardspage_List_Title_Title1_Box{
            height: 100%;
            width: 100%;
            background-color: white;
            color: #b38632;
            font-size: 250%;
            font-family: "Ladyrose";
            display: flex;
            align-items: center;
            justify-content: end;
            padding-right: 5%;
            z-index: 1;
          }
        }
        .Awardspage_List_Title_Title2{
          width: 30%;
          height: 35%;
          margin-left: 20%;
          .Awardspage_List_Title_Title2_Box{
            height: 100%;
            width: 100%;
            background-color: #E4E1D8;
            color: #2d2e2d;
            font-size: 250%;
            font-family: "Ladyrose";
            display: flex;
            align-items: center;
            padding-left: 5%;
            z-index: 1;
          }
        }
      }
      .Awardspage_List_Border{
        width: 60%;
        height: 60%;
        background-color: #E4E1D8;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;
        z-index: 1;
        &::-webkit-scrollbar {
          width: 15px;
      }

      &::-webkit-scrollbar-track {
          background-color: white;

      }

      &::-webkit-scrollbar-thumb {
          background-color: #2d2e2d84;
          border-radius: 180px;
  border:3px solid transparent;
  background-clip: content-box;
      }
        .Awardspage_List_Border_Item{
          width: 100%;
          min-height: 10px;
          display: inline-flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-top: 5%;
          margin-bottom: 5%;
          .Awardspage_List_Border_Item_Prize{
            height: 60px;
            width:8%;
            color: white;
            background-color: black;
            font-family: "Ladyrose";
            display: flex;     
            justify-content: center;
            align-items: center;
            font-size: 120%; 
            margin-right: 1%;        
          }
          .Awardspage_List_Border_Item_Content{
            width: 70%;
            height: 50px;
            color: black;
            border: 2px solid black;
            display: flex; 
            flex-direction: row;    
            justify-content: space-evenly;
            align-items: center;      

            .Awardspage_List_Border_Item_Content1{
              width: 55%;
              font-family: "Ladyrose";
              font-size: 100%;
              text-align: left;
              display: flex;
              align-items: center;
              justify-content: start;

            } 
            .Awardspage_List_Border_Item_Content_1{
              width: 55%;
              font-family: "Ladyrose";
              font-size: 90%;
              text-align: left;
              display: flex;
              align-items: center;
              justify-content: start;
              z-index: 1;
            } 
            .Awardspage_List_Border_Item_Content2{
              font-family: "Medium15";
              font-size: 100%;
              width: 40%;
              text-align: left;
              display: flex;
              align-items: center;
              justify-content: end;

            }
          }
        }
      }
    }
    .Awardspage_Seperate2{
      height: 5vh;
      background-color: #E4E1D8;
    }
    .Awardspage_Footer{
      height: 25vh;
    }
  }
}

@media screen and (max-width: 800px){
    .Awardspage{
      background-color: #E4E1D8;
      .Awardspage_Header{
        height: 5vh;
        
      }
      .Awardspage_Achive{
        height: 60vh;
        width: 100%;
        background-color: white;
        position: relative;
        .Awardspage_Achive_Bg{
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 0;
          top: 0;
        }
        .Awardspage_Achive_Pagename{
          font-size: 180%;
          width: 100%;
          font-family: "Ladyrose";
          display: flex;
          justify-content: center;
          align-items: end;
          height: 15%;
          color: #b38632;
          letter-spacing: 2px;
          z-index: 1;
        }
        .Awardspage_Achive_Title{
          height: 20%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-bottom: 3%;
          .Awardspage_Achive_Title1{
            font-size: 150%;
            font-family: "Ladyrose";
            height: 30px;
          letter-spacing: 2px;
          margin-bottom: 1%;
          z-index: 1;
          }
          .Awardspage_Achive_Title2_Border{
            display: flex;
          align-items: center;
          justify-content: center;
          width: 225px;
          height: 30px;
          background-color: #2d2e2d;
          z-index: 1;
            .Awardspage_Achive_Title2{
             color: #E4E1D8;
            font-size: 150%;
            font-family: "Ladyrose";
            letter-spacing: 2px;


              
            }
          }
        }
        .Awardspage_Achive_Text{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 15%;
        margin-bottom: 10%;
          .Awardspage_Achive_Text_Content{
            width: 90%;
            font-family: "SemiBold15";
            font-size: 70%;
            text-align: center;
            line-height: 15px;
            letter-spacing: 0.5px;
            z-index: 1;
          }
        }
        
        .Awardspage_Achive_Achive{
          height: 30%;
          width: 100%;
          .Awardspage_Achive_Achive_Content{
            height: 70%;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            .Awardspage_Achive_Achive_Convention{
              width: 25vw;
              height: 25vw;
              background-color: #2d2e2d;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              box-shadow: 5px 5px 10px 1px grey;
              z-index: 1;
              .Awardspage_Achive_Achive_Convention_Img{
                width: 67%;
                height: 35%;
                margin-left: 5%;
              }
              .Awardspage_Achive_Achive_Convention_Text{
                width: 100%;
              height: 10%;
              font-family: "Ladyrose";
              text-align: center;
              font-size: 60%;
              line-height: 12px;
              color: #E4E1D8;
              }
            }
            .Awardspage_Achive_Achive_YrsExper{
              width: 30vw;
              height: 30vw;
              background-color: #2d2e2d;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              box-shadow: 5px 5px 10px 1px grey;
              z-index: 1;
              .Awardspage_Achive_Achive_YrsExper_Img{
                width: 40%;
                height: 55%;
              }
              .Awardspage_Achive_Achive_YrsExper_Text{
                width: 100%;
                height: 25%;
                font-family: "Ladyrose";
                text-align: center;
                font-size: 70%;
                line-height: 12px;
                color: #E4E1D8;
              }
            }
            .Awardspage_Achive_Achive_Trophies{
              width: 25vw;
              height: 25vw;
              background-color: #2d2e2d;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              box-shadow: 5px 5px 10px 1px grey;
              z-index: 1;
              .Awardspage_Achive_Achive_Trophies_Img{
                width: 75%;
                height: 35%;
                margin-left: 5%;
              }
              .Awardspage_Achive_Achive_Trophies_Text{
                width: 100%;
                height: 10%;
                font-family: "Ladyrose";
                text-align: center;
                font-size: 65%;
                color: #E4E1D8;
              }
            }
          }
        }
      }
      .Awards_Awards{
        position: relative;
        height: 60vh;
        width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .Awards_Awards_Bg{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
      }
        .Awards_Awards_Title{
          padding-top: 5%;
          height: 20%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .Awards_Awards_Title_Title1{
            width: 100%;
            height: 40%;
            display: flex;
            .Awards_Awards_Title_Title1_Box{
              height: 100%;
              width: 65%;
              background-color: white;
              color: #b38632;
              font-size: 130%;
              font-family: "Ladyrose";
              display: flex;
              align-items: center;
              justify-content: end;
              padding-right: 5%;
              z-index: 1;
            }
          }
          .Awards_Awards_Title_Title2{
            width: 100%;
            height: 40%;
            display: flex;

            justify-content: end;
            
            .Awards_Awards_Title_Title2_Box{
              height: 100%;
              width: 65%;
              background-color: #2d2e2d;
              color: #E4E1D8;
              font-size: 125%;
              font-family: "Ladyrose";
              display: flex;
              align-items: center;
              justify-content:left;
              padding-left: 5%;
              padding-right: 5%;
              z-index: 1;
            }
          }
        }
        .Awards_Awards_Competition_Logo{
          height: 60%;
          width: 70%;
          display: grid;
          grid-template-columns: 8% 8% 8% 8% 8% 8% 8% 8% 8% 8% 8% 8%;
          grid-template-rows: 1fr 1fr 1fr;

          align-items: center;
          justify-content: center;
          column-gap: 2%;
          z-index: 1;
          .Awards_Awards_Competition_Logo_Container1{
            width: 70%;
            height: 40%;
            grid-column: span 3;
            margin: auto;
            .Competition_Logo1{
              width: 100%;
              height: 100%;
      
            }
          }
          .Awards_Awards_Competition_Logo_Container2{
            margin: auto;
            width: 55%;
            height: 50%;
            grid-column: span 3;
            .Competition_Logo2{
              width: 100%;
              height: 100%;
            }
          }
          .Awards_Awards_Competition_Logo_Container3{
            margin: auto;
            width: 60%;
            height: 50%;
            grid-column: span 3;
            .Competition_Logo3{
              width: 100%;
                height: 100%;
            }
          }
          .Awards_Awards_Competition_Logo_Container4{
            margin: auto;
            width: 47%;
            height: 40%;
            grid-column: span 3;
            .Competition_Logo4{
              width: 100%;
              height: 100%;
            }
          }
        .Awards_Awards_Competition_Logo_Container5{
          margin: auto;
          width: 75%;
          height: 40%;
          grid-column: span 3;
          background-color: black;
          .Competition_Logo5{
            width: 100%;
            height: 100%;
          }
        }
          .Awards_Awards_Competition_Logo_Container6{
            margin: auto;
            width: 58%;
            height: 50%;
            grid-column: span 3;
            .Competition_Logo6{
              width: 100%;
              height: 100%;}
          }
          .Awards_Awards_Competition_Logo_Container7{
            margin: auto;
            width: 80%;
            height: 45%;
            grid-column: span 3;
            .Competition_Logo7{
              width: 100%;
                height: 100%;
            }
          }
          .Awards_Awards_Competition_Logo_Container8{
            margin: auto;
            width: 65%;
            height: 55%;
            grid-column: span 3;
            .Competition_Logo8{
              width: 100%;
                  height: 100%;
            }
}
         .Awards_Awards_Competition_Logo_Container9{
          margin: auto;
          width: 70%;
          height: 25%;
          grid-column: span 4;
          .Competition_Logo9{
            width: 100%;
            height: 100%;
          }
         }
         .Awards_Awards_Competition_Logo_Container10{
          width: 80%;
          height: 35%;
          grid-column: span 4;
          margin: auto;
          .Competition_Logo10{
            width: 100%;
            height: 100%;
          }
         }
         .Awards_Awards_Competition_Logo_Container11{
          width: 80%;
          height: 40%;
          grid-column: span 4;
          margin: auto;
          .Competition_Logo11{
            width: 100%;
            height: 100%;
          }
         }
        }
      }
      .Awardspage_List{
        height: 60vh;
        width: 100%;
        background-color: #2d2e2d;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .Awardspage_List_Bg{
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 0;
        }
        .Awardspage_List_Border{
          width: 90vw;
          height: 90vw;
          background-color: #E4E1D8;
          display: flex;
          flex-direction: column;
          overflow-x: hidden;
          overflow-y: auto;
          z-index: 1;
          .Awardspage_List_Border_Item{
            width: 100%;
            min-height: 30px;
            display: inline-flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            margin-top: 5%;
            margin-bottom: 8%;

            .Awardspage_List_Border_Item_Prize{
              height: 35px;
              width:13%;
              color: white;
              background-color: black;
              font-family: "Ladyrose";
                display: flex;     
                justify-content: center;
                align-items: center;
                font-size: 80%;         
            }
            .Awardspage_List_Border_Item_Content{
              width: 75%;
              height: 35px;
              color: black;
            border: 1px solid black;
              display: flex; 
              flex-direction: column;    
              justify-content: space-evenly;
              align-items: center;      

              .Awardspage_List_Border_Item_Content1{
                width: 90%;
                font-family: "Ladyrose";
                font-size: 70%;
                text-align: left;


              } 
              .Awardspage_List_Border_Item_Content_1{
                width: 90%;
                font-family: "Ladyrose";
                font-size: 65%;
                text-align: left;
                z-index: 1;
              } 
              .Awardspage_List_Border_Item_Content2{
                font-family: "Medium15";
                font-size: 80%;
                width: 90%;
                text-align: left;

              }
            }
          }
        }
      }
      .Awardspage_Footer{
        height: 25vh;
      }
    }
}