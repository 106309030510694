body {
  height: '100%';
  overflow: auto;
  overscroll-behavior: contain;
  overscroll-behavior-y: none;
}
html {
  height: '100%';
  overflow: auto;
  overscroll-behavior: contain;
  overscroll-behavior-y: none;
}
*, *:before, *:after {
  box-sizing: inherit;
}

@font-face {
  font-family: "BancheroBold";
  src: local("BancheroBold"),url("../assets/fonts/Banchero-Bold.otf") format("truetype");
}
@font-face {
  font-family: "Light15";
  src: local("Light15"),url("../assets/fonts/SharpGrotesk-Light15.otf") format("truetype");
}
@font-face {
  font-family: "CeniosdemoRegular";
  src: local("CeniosdemoRegular"),url("../assets/fonts/CeniosdemoRegular-MVKzY.otf") format("truetype");
}
@font-face {
  font-family: "Ladyrose";
  src: local("Ladyrose"),url("../assets/fonts/ladyrose.otf") format("truetype");
}
@font-face {
  font-family: "SemiBold15";
  src: local("SemiBold15"),url("../assets/fonts/SharpGrotesk-SemiBold15.otf") format("truetype");
}
@font-face {
  font-family: "Medium15";
  src: local("Medium15"),url("../assets/fonts/SharpGrotesk-Medium15.otf") format("truetype");
}
@media screen and (min-width: 992px){
  .Homepage{
    scroll-behavior: smooth;
    overscroll-behavior: none;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    .popUp, .overlay {
      width: 100%;
      max-height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: fixed;
      z-index: 2;


  }
  
    .overlay {
        background: rgba(49,49,49,0.8);
        
    }
    .popUP_Content {
        position: absolute; 
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30%;
        height: 80%;
      z-index: 3;
    }
    .Homepage_Header{
      height: 8vh;
      background-color: #E4E1D8;
    }
    .Homepage_PageName{
      width: 100%;
      height: 90vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      background-color: #E4E1D8;
      background-image:url('https://ik.imagekit.io/suva2tmrt/mainpage/7.webp?updatedAt=1706288018712');
      background-position: 0% 0%;
      background-repeat: no-repeat;
      background-size:50%;
      .Homepage_PageName_Minh{
        width: 40%;
        height: 24%;
        display: flex;
        margin-left: 13%;
        align-items: center;

        .Homepage_PageName_Minh_Img{
          width: 60%;
          margin-top: 5%;
          height: 100%;
        }
      }
      .Homepage_PageName_PMP{
        width: 58%;
        height: 11%;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: right;
        z-index: 1;
        .Homepage_PageName_PMP_Img{
          margin-right: 7%;
          width: 70%;
          height: 70%;
        }
      }
      .Homepage_PageName_Text{
        width: 40%;
        height: 13%;
        display: flex;
        align-items: center;
        margin-left: 13%;
        font-family: "CeniosdemoRegular";
        font-size: 30px;
        letter-spacing: 7px;
      }
      .Homepage_PageName_Brother_Img{
        background-image:url('../assets/imgBrother/mybrother5.webp');
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        width: 50%;
        height: 100%;
        position: absolute;
        right: 0;
        z-index: 0;
      }
    }
    .Homepage_Seperate{
      height: 10vh;
      background-color: #cec8ba;
    }
    .Homepage_Competition_Img{
      height: 100vh;
      background-color: #2d2e2d;
      display: grid;
      grid-template-columns: 20% 20% 25%;
      grid-template-rows: 55% 35%;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      justify-content: center;
      padding-top: 5%;

      padding-bottom: 1%;
      position: relative;
    
      .Homepage_Competition_Img_Bg{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
      }
      .Homepage_Competition_Img1{
        grid-column: span 2;
        background-image:url('../assets/imgHome/imgHomePC1.webp');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;

      }
      .Homepage_Competition_Img2{
        grid-row: span 2;
        background-image:url('../assets/imgHome/imgHomePC2.webp');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;

      }
      .Homepage_Competition_Img3{
        
        background-image:url('../assets/imgHome/imgHomePC3.webp');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
      }
      .Homepage_Competition_Img4{
        background-image:url('../assets/imgHome/imgHomePC4.webp');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
      }
    }
    .Homepage_Competition_Logo{
      position: relative;
      height: 75vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .Homepage_Competition_Logo_Container{
        width: 60%;
        height: 80%;
        display: grid;
        grid-template-columns: 8% 8% 8% 8% 8% 8% 8% 8% 8% 8% 8% 8%;
        grid-template-rows: auto auto auto;
        .Competition_Logo1{
          width: 60%;
          height: 40%;
          grid-column: span 3;
          margin: auto;
          z-index: 1;
        }
        .Competition_Logo2{
          margin: auto;
          width: 50%;
          height: 60%;
          grid-column: span 3;
          z-index: 1;
        }
        .Competition_Logo3{
          margin: auto;
          width: 55%;
          height: 60%;
          grid-column: span 3;
          z-index: 1;
        }
        .Competition_Logo4{
          margin: auto;
          width: 40%;
          height: 43%;
          grid-column: span 3;
          z-index: 1;
        }
        .Competition_Logo5{
          margin: auto;
          width: 65%;
          height: 45%;
          grid-column: span 3;
          background-color: black;
          z-index: 1;
        }
        .Competition_Logo6{
          margin: auto;
          width: 48%;
          height: 60%;
          grid-column: span 3;
          z-index: 1;
        }
        .Competition_Logo7{
          margin: auto;
          width: 70%;
          height: 40%;
          grid-column: span 3;
          z-index: 1;
        }
        .Competition_Logo8{
          margin: auto;
          width: 50%;
          height: 60%;
          grid-column: span 3;
          z-index: 1;
        }
        .Competition_Logo9{
          margin: auto;
          width: 70%;
          height: 30%;
          grid-column: span 4;
          z-index: 1;
        }
        .Competition_Logo10{
          width: 70%;
          height: 50%;
          grid-column: span 4;
          margin: auto;
          z-index: 1;
        }
        .Competition_Logo11{
          width: 75%;
          height: 57%;
          grid-column: span 4;
          margin: auto;
          z-index: 1;
        }
      }
      .Homepage_Competition_Logo_Bg{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
      }
      
    }
    .Homepage_Introduce{
      height: 60vh;
      width: 100%;
      background-color: #E4E1D8;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: relative;
      .Homepage_Introduce_Bg{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
      }
      .Homepage_Introduce_Video{
        width: 30%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: end;
        z-index: 1;
        .Homepage_Introduce_Video_Control{
          height: 70%;
          width: 90%;

        }
      }
      .Homepage_Introduce_Text{
        height: 80%;
        width: 30%;
        display: flex;
        justify-content: center;
        align-items:center;
        z-index: 1;
        .Homepage_Introduce_Text_QuotationMark{
          width: 100%;
          height: 60%;
          position: relative;
          display: flex;

          align-items: center;
          .Homepage_Introduce_QuotationMark1{
            width: 10%;
            height: 30%;
            position: absolute;
            top: 0;
            left: 0;
          }
          .Homepage_Introduce_MainText{
            width: 65%;  

            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 10%;
            line-height: 25px;
            letter-spacing: 0.5px;
            text-align: center;
            .Homepage_Introduce_MainText1{
              font-family: "BancheroBold";
              font-size: 100%;
            }
            .Homepage_Introduce_MainText2{
              font-family: "BancheroBold";
              font-size: 100%;
            }
          }
          .Homepage_Introduce_QuotationMark2{
            width: 10%;
            height: 30%;
            position: absolute;
            bottom: 0;
            right: 10%;
          }
        }
      }
    }
    .Homepage_Seperate2{
      height: 10vh;
      width: 100%;
      background-color: #2d2e2d;
    }
    .Homepage_About{
      height: 100vh;
      width: 100%;
      padding-top: 5%;
      background-color: #E4E1D8;
      position: relative;
      z-index: 0;
      display: flex;
      flex-direction: column;
      .Homepage_About_Bg{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -2;
      }
      .Homepage_About_Title{
        display: flex;
        flex-direction: row;
        height: 40%;
        margin-left: 12%;
        margin-top: 5%;
        .Homepage_About_Title1{
          font-size: 350%;
          height: 110%;
          width: 35%;
          font-family: "Ladyrose";
          padding-top: 3%;
          position: relative;
          text-align: right;
          letter-spacing: 2px;
          z-index: 1;
          display: flex;
          justify-content: end;
          align-items: end;
          padding-bottom: 12px;
        }
        .Homepage_About_Title2{
          height: 110%;
          width: 25%;
          display: flex;

          align-items: end;
          background-image:url('../assets/imgBrother/mybrother8.webp');
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: cover;
          font-size: 350%;
          font-family: "Ladyrose";
          margin-left: 1%;
          padding-left: 1%;
          padding-top: 3%;
          color: #E4E1D8;
          letter-spacing: 2px;
          z-index: 1;
          border-right: 12px solid #E4E1D8;
          border-bottom: 12px solid #E4E1D8;
        }
        .Homepage_About_Title_Img{
          position: absolute;
          top: 30%;
          right: -130%;
          height: 130%;
          width: 80%;
          background-image:url('../assets/imgBrother/mybrother7.webp');
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: cover;

          z-index: 0;
        }
      }
      .Homepage_About_Text{
        width: 35%;
        height: 30%;
        line-height: 20px;
        letter-spacing: 0.5px;
        z-index: 1;
        text-align: right;
        position: absolute;

        top: 63%;
        left: 18%;
        .Homepage_About_Text_Text{
          font-family: "SemiBold15";
          font-size: 90%;
          height: 30%;
        }
    
      }
      .Homepage_About_Button{
        width: 53%;
        top: 74%;

        height: 10%;
        display: flex;
        align-items: center;
        justify-content: right;
        position: absolute;
        z-index: 3;
        .Homepage_About_Button_Text{
          width: 85px;
          height: 30%;
          color: #E4E1D8;
          background-color: #2d2e2d;
          border: none;
          font-family: "BancheroBold";
          cursor: pointer;
          font-size: 70%;
        }
      }
    }
    .sepereate{
      height: 10vh;
      background-color: #2d2e2d;
    }
    .Homepage_Achive{
      position: relative;
      height: 100vh;
      width: 100%;
      background-color: white;
      padding-top: 5%;
      padding-bottom: 5%;
      z-index: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .Homepage_Achive_Bg
      {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: -2;
      }
      .Homepage_Achive_Title{
        height: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .Homepage_Achive_Title1{
          font-size: 250%;
          font-family: "Ladyrose";
          letter-spacing: 2px;
          z-index: 1;
          height:35%;
        }
          .Homepage_Achive_Title2{
            height: 30%;
          font-size: 250%;
          font-family: "Ladyrose";
          letter-spacing: 2px;
          z-index: 1;
          }

      }
      .Homepage_Achive_Text{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45%;
        height: 20%;
        margin-bottom: 6%;
        letter-spacing: 0.5px;
        background-color: #2d2e2d;
        border-bottom-right-radius: 50px;
        border-top-left-radius: 50px;
        .Homepage_Achive_Text_Content{
          width: 80%;
          font-family: "SemiBold15";
          font-size: 110%;
          text-align: center;
          line-height: 25px;
          z-index: 1;
          color: #E4E1D8;
        }
      }
      .Homepage_Achive_Achive{
        height: 45%;
        width: 100%;
        
        .Homepage_Achive_Achive_Content{
          height: 70%;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          
          .Homepage_Achive_Achive_Convention{
            width: 20vw;
            height: 20vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;

            z-index: 1;
            .Homepage_Achive_Achive_Convention_Img_Container{
              width: 35%;
              height: 35%;
              border-radius: 50%;
              background-color: #E4E1D8;
              box-shadow: 5px 5px 10px 1px grey;
              display: flex;
              justify-content: center;
              align-items: center;
              .Homepage_Achive_Achive_Convention_Img{
                width: 80%;
                height: 43%;
              }
            }
            .Homepage_Achive_Achive_Convention_Text{
              width: 50%;
              height: 10%;
              font-family: "Ladyrose";
              text-align: center;
              font-size: 100%;
              line-height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
            }
            .Homepage_Achive_Achive_Convention_Text_Detail{
              width: 60%;
              height: 15%;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              font-family: "Medium15";
              font-size: 90%;
              line-height: 20px;
            }
          }
          .Homepage_Achive_Achive_YrsExper{
            width: 20vw;
            height: 20vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;

            z-index: 1;
            .Homepage_Achive_Achive_YrsExper_Img_Container{
              width: 35%;
              height: 35%;
              border-radius: 50%;
              background-color: #E4E1D8;
              box-shadow: 5px 5px 10px 1px grey;
              display: flex;
              justify-content: center;
              align-items: center;
              .Homepage_Achive_Achive_YrsExper_Img{
                width: 35%;
                height: 50%;
              }
            }
           
            .Homepage_Achive_Achive_YrsExper_Text{
              width: 50%;
              height: 10%;
              font-family: "Ladyrose";
              text-align: center;
              font-size: 100%;
              line-height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
            }
            .Homepage_Achive_Achive_YrsExper_Text_Detail
            {
              width: 80%;
              height: 15%;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              font-family: "Medium15";
              font-size: 90%;
              line-height: 20px;
            }
          }
          .Homepage_Achive_Achive_Trophies{
            width: 20vw;
            height: 20vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;

            z-index: 1;
            .Homepage_Achive_Achive_Trophies_Img_Container{
              width: 35%;
              height: 35%;
              border-radius: 50%;
              background-color: #E4E1D8;
              box-shadow: 5px 5px 10px 1px grey;
              display: flex;
              justify-content: center;
              align-items: center;
              .Homepage_Achive_Achive_Trophies_Img{
                width: 80%;
                height: 43%;
              }
            }
            
            .Homepage_Achive_Achive_Trophies_Text{
              width: 50%;
              height: 10%;
              font-family: "Ladyrose";
              text-align: center;
              font-size: 100%;
              line-height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
            }
            .Homepage_Achive_Achive_Trophies_Text_Detail
            {
              width: 70%;
              height: 15%;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              font-family: "Medium15";
              font-size: 90%;
              line-height: 20px;

            }
          }
        }
        .Homepage_Achive_Achive_Details{
          height: 15%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          .Homepage_Achive_Achive_Details_Button{
            height: 30px;
            width: 140px;
            margin-top: 10%;
            font-family: "BancheroBold";
            font-size: 90%;
            background-color: #2d2e2d;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #E4E1D8;

          }
        }
      }
    }
    .Homepage_Gallery{
      position: relative;
      height: 130vh;
      width: 100%;
      background-color:#2d2e2d ;
      padding-bottom: 3%;
      z-index: 0;
      .Homepage_Gallery_Bg{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -2;
      }
      .Homepage_Gallery_Name{
        display: flex;
        justify-content: center;
        align-items: end;
        width: 100%;
        height: 10%;
        color:gray;
        font-family: "CeniosdemoRegular";
        font-size: 200%;
        letter-spacing: 5px;
        z-index:1;
      }
      .Homepage_Gallery_Title{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 7%;
        color:#b38632;
        font-family: "Ladyrose";
        font-size: 300%;
        letter-spacing: 5px;
        z-index: 1;
      }
      .Homepage_Gallery_Text{
        height: 10%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 20px;
        letter-spacing: 0.5px;
        margin-bottom: 5%;
        .Homepage_Gallery_Text_Content{
          width: 45%;
          color: #E4E1D8;
          font-family: "Medium15";
          font-size: 110%;
          text-align: center;
          z-index: 1;
        }
      }
      .Homepage_Gallery_Img{
        height: 60%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .Homepage_Gallery_Img_Styles{
          width: 68%;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          margin-bottom: 3%;
          letter-spacing: 1.5px;


          .Homepage_Gallery_Img_Styles_Border{
            height: 100%;
            z-index: 3;
            .Homepage_Gallery_Img_Styles_Style1,.Homepage_Gallery_Img_Styles_Style2,.Homepage_Gallery_Img_Styles_Style3,.Homepage_Gallery_Img_Styles_Style4,.Homepage_Gallery_Img_Styles_Style5,.Homepage_Gallery_Img_Styles_Style6{
              color: gray;
              font-family: "Ladyrose";
              font-size: 90%;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              z-index: 2;
            }
            .Homepage_Gallery_Img_Styles_Style1{
              width: 100%;
              height: 80%;
            }
            .Homepage_Gallery_Img_Styles_Style2{
              width: 100%;
              height: 80%;
            }
            .Homepage_Gallery_Img_Styles_Style3{
              width: 100%;
              height: 80%;
            }
            .Homepage_Gallery_Img_Styles_Style4{
              width: 100%;
              height: 80%;
            }
            .Homepage_Gallery_Img_Styles_Style5{
              width: 100%;
              height: 80%;
            }
            .Homepage_Gallery_Img_Styles_Style6{
              width: 100%;
              height: 80%;
            }
            .active{
              
              border-bottom: 2px solid white;
              color: white;
            }
          }
        }

        .Homepage_Gallery_Img_Container{
          height: 90%;
          width: 60%;
          display: grid;
          grid-template-columns: auto auto auto auto;
          background-color: #2d2e2d;
          overflow-y: auto;
          overflow-x: hidden;
          column-gap: 15px;
          row-gap: 20px;
          z-index: 4;
          position: relative;
          &::-webkit-scrollbar {
            width: 15px;
        }
  
        &::-webkit-scrollbar-track {
            background-color: #1E1E1E;

        }
  
        &::-webkit-scrollbar-thumb {
            background-color: #b38632bd;
            border-radius: 180px;
    border:3px solid transparent;
    background-clip: content-box;
        }
          .Realism_div_1, .Realism_div_2, .Realism_div_3, .Realism_div_4, .Realism_div_5, .Realism_div_6, .Realism_div_7, .Realism_div_8, .Realism_div_9, .Realism_div_10,.Realism_div_11, .Realism_div_12, .Realism_div_13, .Realism_div_14, .Realism_div_15, .Realism_div_23, .Realism_div_24, .Realism_div_25, .Realism_div_16, .Realism_div_17, .Realism_div_18, .Realism_div_19, .Realism_div_20, .Realism_div_21, .Realism_div_22{
            width: 100%;
            height: 100%;
            .Realism_1,.Realism_8,.Realism_11,.Realism_2, .Realism_3, .Realism_4, .Realism_5, .Realism_6, .Realism_7, .Realism_9, .Realism_10, .Realism_12, .Realism_13, .Realism_14, .Realism_15, .Realism_16, .Realism_17, .Realism_18, .Realism_19, .Realism_20, .Realism_21, .Realism_22, .Realism_23, .Realism_24, .Realism_25{
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          
        
          .Portrait_div_1,.Portrait_div_2,.Portrait_div_3,.Portrait_div_4,.Portrait_div_5,.Portrait_div_6,.Portrait_div_7,.Portrait_div_8,.Portrait_div_9,.Portrait_div_10,.Portrait_div_11,.Portrait_div_12,.Portrait_div_13,.Portrait_div_14,.Portrait_div_15,.Portrait_div_16,.Portrait_div_17,.Portrait_div_18,.Portrait_div_19,.Portrait_div_20{
            width: 100%;
            height: 100%;
            .Portrait_1,.Portrait_2,.Portrait_3,.Portrait_4,.Portrait_5,.Portrait_6,.Portrait_7,.Portrait_8,.Portrait_9,.Portrait_10,.Portrait_11,.Portrait_12,.Portrait_13,.Portrait_14,.Portrait_15,.Portrait_16,.Portrait_17,.Portrait_18,.Portrait_19,.Portrait_20{
              width: 100%;
            height: 100%;
            object-fit: cover;
            }

          }
          
          
          .FineLine_div_1,.FineLine_div_2,.FineLine_div_3,.FineLine_div_4,.FineLine_div_5,.FineLine_div_6,.FineLine_div_7,.FineLine_div_8,.FineLine_div_9,.FineLine_div_10,.FineLine_div_11,.FineLine_div_12,.FineLine_div_13,.FineLine_div_14,.FineLine_div_15{
            width: 100%;
            height: 100%;
            .FineLine_1,.FineLine_2,.FineLine_3,.FineLine_4,.FineLine_5,.FineLine_6,.FineLine_7,.FineLine_8,.FineLine_9,.FineLine_10,.FineLine_11,.FineLine_12,.FineLine_13,.FineLine_14,.FineLine_15{
              height: 100%;
              width: 100%;
              object-fit: cover;
            }

          }
         
 
          .Color_div_1,.Color_div_2,.Color_div_3,.Color_div_4,.Color_div_5,.Color_div_6,.Color_div_7,.Color_div_8,.Color_div_9,.Color_div_10,.Color_div_11,.Color_div_12,.Color_div_13,.Color_div_14,.Color_div_15,.Color_div_16,.Color_div_17,.Color_div_18,.Color_div_19,.Color_div_20,.Color_div_21,.Color_div_22,.Color_div_23,.Color_div_24,.Color_div_25,.Color_div_26,.Color_div_27,.Color_div_28,.Color_div_29,.Color_div_30,.Color_div_31,.Color_div_32,.Color_div_33,.Color_div_34,.Color_div_35{
            width: 100%;
            height: 100%;
            .Color_1,.Color_2,.Color_3,.Color_4,.Color_5,.Color_6,.Color_7,.Color_8,.Color_9,.Color_10,.Color_11,.Color_12,.Color_13,.Color_14,.Color_15,.Color_16,.Color_17,.Color_18,.Color_19,.Color_20,.Color_21,.Color_22,.Color_23,.Color_24,.Color_25,.Color_26,.Color_27,.Color_28,.Color_29,.Color_30,.Color_31,.Color_32,.Color_33,.Color_34,.Color_35{
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

          }
          

          .Blackgrey_div_1,.Blackgrey_div_2,.Blackgrey_div_3,.Blackgrey_div_4,.Blackgrey_div_5,.Blackgrey_div_6,.Blackgrey_div_7,.Blackgrey_div_8,.Blackgrey_div_9,.Blackgrey_div_10,.Blackgrey_div_11,.Blackgrey_div_12,.Blackgrey_div_13,.Blackgrey_div_14,.Blackgrey_div_15,.Blackgrey_div_16,.Blackgrey_div_17,.Blackgrey_div_18,.Blackgrey_div_19,.Blackgrey_div_20,.Blackgrey_div_21,.Blackgrey_div_22,.Blackgrey_div_23,.Blackgrey_div_24,.Blackgrey_div_25{
            width: 100%;
            height: 100%;
            .Blackgrey_1,.Blackgrey_2,.Blackgrey_3,.Blackgrey_4,.Blackgrey_5,.Blackgrey_6,.Blackgrey_7,.Blackgrey_8,.Blackgrey_9,.Blackgrey_10,.Blackgrey_11,.Blackgrey_12,.Blackgrey_13,.Blackgrey_14,.Blackgrey_15,.Blackgrey_16,.Blackgrey_17,.Blackgrey_18,.Blackgrey_19,.Blackgrey_20,.Blackgrey_21,.Blackgrey_22,.Blackgrey_23,.Blackgrey_24,.Blackgrey_25{
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

          }
         
          .Asian_div_1,.Asian_div_2,.Asian_div_3,.Asian_div_4,.Asian_div_5,.Asian_div_6,.Asian_div_7,.Asian_div_8,.Asian_div_9,.Asian_div_10,.Asian_div_11,.Asian_div_12,.Asian_div_13,.Asian_div_14,.Asian_div_15{
            width: 100%;
            height: 100%;
            .Asian_1,.Asian_2,.Asian_3,.Asian_4,.Asian_5,.Asian_6,.Asian_7,.Asian_8,.Asian_9,.Asian_10,.Asian_11,.Asian_12,.Asian_13,.Asian_14,.Asian_15{
              width: 100%;
            height: 100%;
            object-fit: cover;
            }

          }
          
        }
        
      }
      .Homepage_Gallery_Button{
        width: 100%;
        height: 10%;
        display: flex;
        align-items: center;
        justify-content: center;

        .Homepage_Gallery_Button_Text{
          width: 150px;
          height: 30%;
          background-color: gray;
          font-family: "Ladyrose";
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-size: 100%;
          z-index: 1;
        }
      }
    }
    .Homepage_Seperate3{
      height: 5vh;
      width: 100%;
      background-color: white;
    }
    .Homepage_Contact{
      position: relative;
      background-color:#E4E1D8;
      height: 80vh;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: -2;
      .Homepage_Contact_Bg{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 0;
      }
      .Homepage_Contact_Title{
        width: 100%;
        height: 13%;
        font-family: "Ladyrose";
        color:#2d2e2d;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 250%;
        letter-spacing: 5px;
        margin-top: 4%;
        z-index: 3;

      }
      .Homepage_Contact_Text{
        width: 70%;
        height: 10%;
        font-size: 120%;
        font-family: "Ladyrose";
        color:#b38632;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
      }
      .Homepage_Map{
        height: 45%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
          z-index: 3;
        .Homepage_Map_Size{

          height: 100%;
          width: 45%;
          border: none;
          z-index: 3;
        }
      }
      .Homepage_Contact_Content{
        height: 10%;
        width: 45%;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: auto auto;
        margin-top: 1%;

        .Homepage_Contact_Content_Text1{
          color: #2d2e2d;
          font-family: "SemiBold15";
          display: flex;
          width: 100%;
          align-items: center;
          letter-spacing: 2px;
          font-size: 100%;
          z-index: 1;
        }
        .Homepage_Contact_Content_Text2{
          color: #2d2e2d;
          font-family: "SemiBold15";
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: end;
          letter-spacing: 2px;
          font-size: 100%;
          z-index: 1;
        }
      }
    }
  
    .Homepage_footer{
      height: 25vh;
    }
  }
}

@media screen and (max-width:800px) {
  
  .Homepage{
    .popUp, .overlay {
      width: 100%;
      max-height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: fixed;
      z-index: 2;


  }
  
    .overlay {
        background: rgba(49,49,49,0.8);
        
    }
    .popUP_Content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70%;
        height: 80%;
      z-index: 3;
    }
    .Homepage_Header{
      height: 5vh;
      background-color: #E4E1D8;
    }
    .Homepage_PageName{
      width: 100%;
      height: 30vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;

         background-color: #E4E1D8;
         background-image:url('https://ik.imagekit.io/suva2tmrt/mainpage/1.webp?updatedAt=1706288018221');
        background-position: 0% 60%;
        background-repeat: no-repeat;
        background-size: 50%;
      .Homepage_PageName_Minh{
        width: 50%;
        height: 33%;
        display: flex;
        margin-left: 9%;
        align-items: center;

        .Homepage_PageName_Minh_Img{
          width: 60%;
          margin-top: 5%;
          height: 100%;
        }
      }
      .Homepage_PageName_PMP{
        width: 60%;
        height:18%;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: right;
        z-index: 1;
        .Homepage_PageName_PMP_Img{
          margin-right: 14%;
          width: 70%;
          height: 60%;
        }
      }
      .Homepage_PageName_Text{
        width: 40%;
        height: 13%;
        display: flex;
        align-items: center;
        margin-left: 9%;
        font-family: "CeniosdemoRegular";
        font-size: 12px;
        letter-spacing: 2px;
      }
      .Homepage_PageName_Brother_Img{
        background-image:url('../assets/imgBrother/mybrother5.webp');
        background-position: 50% 30%;
        background-repeat: no-repeat;
        background-size: cover;
        width: 50%;
        height: 100%;
        position: absolute;
        right: 0;
        z-index: 0;
      }
    }
    .Homepage_Seperate{
      height: 5vh;
      background-color: #cec8ba;
    }
    .Homepage_Competition_Img{
      height: 60vh;
      background-color: #2d2e2d;
      display: grid;
      grid-template-columns: 25% 25% 25%;
      grid-template-rows: 60% 30%;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      justify-content: center;
      padding-top: 10%;

      padding-bottom: 6%;
      position: relative;
    
      .Homepage_Competition_Img_Bg{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
      }
      .Homepage_Competition_Img1{
        grid-column: 1 / 4;
        background-image:url('../assets/imgHome/imgHome1.webp');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;

      }
      .Homepage_Competition_Img2{
        background-image:url('../assets/imgHome/imgHome2.webp');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
      }
      .Homepage_Competition_Img3{
        background-image:url('../assets/imgHome/imgHome3.webp');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
      }
      .Homepage_Competition_Img4{
        background-image:url('../assets/imgHome/imgHome4.webp');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
      }
    }
    .Homepage_Competition_Logo{
      position: relative;
      height: 27vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    
      .Homepage_Competition_Logo_Bg{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
      }
      .Homepage_Competition_Logo_Container{
        width: 80%;
        height: 80%;
        display: grid;
        grid-template-columns: 9% 9% 9% 9% 9% 9% 9% 9% 9% 9% 9% 9%;
        grid-template-rows: auto auto auto;
      }
      .Competition_Logo1{
        width: 60%;
        height: 47%;
        grid-column: span 3;
        margin: auto;
        z-index: 1;
      }
      .Competition_Logo2{
        margin: auto;
        width: 45%;
        height: 65%;
        grid-column: span 3;
        z-index: 1;
      }
      .Competition_Logo3{
        margin: auto;
        width: 50%;
        height: 70%;
        grid-column: span 3;
        z-index: 1;
      }
      .Competition_Logo4{
        margin: auto;
        width: 35%;
        height: 50%;
        grid-column: span 3;
        z-index: 1;
      }
      .Competition_Logo5{
        margin: auto;
        width: 65%;
        height: 55%;
        grid-column: span 3;
        background-color: black;
        z-index: 1;
      }
      .Competition_Logo6{
        margin: auto;
        width: 48%;
        height: 70%;
        grid-column: span 3;
        z-index: 1;
      }
      .Competition_Logo7{
        margin: auto;
        width: 70%;
        height: 55%;
        grid-column: span 3;
        z-index: 1;
      }
      .Competition_Logo8{
        margin: auto;
        width: 50%;
        height: 75%;
        grid-column: span 3;
        z-index: 1;
      }
      .Competition_Logo9{
        margin: auto;
        width: 70%;
        height: 40%;
        grid-column: span 4;
        z-index: 1;
      }
      .Competition_Logo10{
        width: 70%;
        height: 52%;
        grid-column: span 4;
        margin: auto;
        z-index: 1;
      }
      .Competition_Logo11{
        width: 75%;
        height: 63%;
        grid-column: span 4;
        margin: auto;
        z-index: 1;
      }
    }
    .Homepage_Introduce{
      height: 39vh;
      width: 100%;
      background-color: #E4E1D8;
      display: flex;
      flex-direction: row;
      position: relative;
      .Homepage_Introduce_Bg{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
      }
      .Homepage_Introduce_Video{
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: end;
        z-index: 1;
        .Homepage_Introduce_Video_Control{
          height: 70%;
          width: 70%;
          margin-right: 8%;
        }
      }
      .Homepage_Introduce_Text{
        height: 85%;
        width: 40%;
        display: flex;
        justify-content: center;
        align-items:end;
        z-index: 1;
        .Homepage_Introduce_Text_QuotationMark{
          width: 100%;
          height: 60%;
          position: relative;
          display: flex;

          align-items: center;
          .Homepage_Introduce_QuotationMark1{
            width: 20%;
            height: 30%;
            position: absolute;
            top: 1%;
            left: 0;
          }
          .Homepage_Introduce_MainText{
            width: 100%;  
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 5%;
            line-height: 15px;
            letter-spacing: 0.5px;
            .Homepage_Introduce_MainText1{
              font-family: "BancheroBold";
              font-size: 50%;
            }
            .Homepage_Introduce_MainText2{
              font-family: "BancheroBold";
              font-size: 50%;
            }
          }
          .Homepage_Introduce_QuotationMark2{
            width: 20%;
            height: 30%;
            position: absolute;
            bottom: 5%;
            right: 0;
          }
        }
      }
    }
    .Homepage_Seperate2{
      height: 5vh;
      width: 100%;
      background-color: #555555;
      position: relative;
      .Homepage_Seperate2_Bg{
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }
    .Homepage_About{
      height: 60vh;
      width: 100%;
      padding-top: 10%;
      background-color: #E4E1D8;
      position: relative;
      z-index: 0;
      .Homepage_About_Bg{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -2;
      }
      .Homepage_About_Title{
        display: flex;
        flex-direction: row;
        height: 65%;
        margin-left: 12%;

        .Homepage_About_Title1{
          font-size: 180%;
          height: 100%;
          width: 40%;
          font-family: "Ladyrose";
          padding-top: 3%;
          position: relative;
          text-align: right;
          letter-spacing: 2px;
          z-index: 1;
        }
        .Homepage_About_Title2{
          height: 100%;
          width: 60%;
          background-image:url('../assets/imgBrother/mybrother7.webp');
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: cover;
          font-size: 180%;
          font-family: "Ladyrose";
          margin-left: 3%;
          padding-left: 3%;
          padding-top: 3%;
          color: #E4E1D8;
          letter-spacing: 2px;
          z-index: 0;
        }
        .Homepage_About_Title_Img{
          position: absolute;
          top: 20%;
          left: 15%;
          height: 70%;
          width: 130%;
          background-image:url('../assets/imgBrother/mybrother8.webp');
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: cover;
          border: 12px solid #E4E1D8;
          z-index: 1;
        }
      }
      .Homepage_About_Text{
        width: 80%;
        height:10%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-left: 12%;
        margin-top: 10%;
        line-height: 13px;
        letter-spacing: 0.5px;
        z-index: 1;
        .Homepage_About_Text1{
        font-family: "SemiBold15";
          font-size: 65%;
          height: 30%;
        }
        .Homepage_About_Text2{
          width: 100%;
          font-family: "SemiBold15";
          font-size: 65%;

        }
      }
      .Homepage_About_Button{
        width: 90%;
        height: 10%;
        display: flex;
        align-items: center;
        justify-content: right;

        .Homepage_About_Button_Text{
          width: 80px;
          height: 40%;
          color: #E4E1D8;
          background-color: #2d2e2d;
          border: none;
          font-family: "BancheroBold";
          cursor: pointer;
          font-size: 60%;
        }
      }
    }
    .Homepage_Achive{
      position: relative;
      height: 50vh;
      width: 100%;
      background-color: white;
      padding-top: 10%;
      padding-bottom: 8%;
      z-index: 0;
      .Homepage_Achive_Bg
      {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: -2;
      }
      .Homepage_Achive_Title{
        height: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding-top: 2%;

        .Homepage_Achive_Title1{
          font-size: 150%;
          font-family: "Ladyrose";
          letter-spacing: 2px;
          z-index: 1;
        }
        .Homepage_Achive_Title2_Border{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 225px;
          height: 30px;
          background-color: #2d2e2d;
          
          .Homepage_Achive_Title2{
            color: #E4E1D8;
            font-size: 145%;
            font-family: "Ladyrose";
            letter-spacing: 2px;

            z-index: 1;
          }
        }
      }
      .Homepage_Achive_Text{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 15%;
        margin-top: 5%;
        margin-bottom: 7%;
        letter-spacing: 0.5px;

        .Homepage_Achive_Text_Content{
          width: 80%;
          font-family: "SemiBold15";
          font-size: 65%;
          text-align: center;
          line-height: 15px;
          z-index: 1;
        }
      }
      .Homepage_Achive_Achive{
        height: 50%;
        width: 100%;
        
        .Homepage_Achive_Achive_Content{
          height: 70%;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
          
          .Homepage_Achive_Achive_Convention{
            width: 25vw;
            height: 25vw;
            background-color: #E4E1D8;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            box-shadow: 5px 5px 10px 1px grey;
            z-index: 1;
            .Homepage_Achive_Achive_Convention_Img{
              width: 67%;
              height: 35%;
            }
            .Homepage_Achive_Achive_Convention_Text{
              width: 100%;
              height: 10%;
              font-family: "Ladyrose";
              text-align: center;
              font-size: 60%;
              line-height: 12px;
            }
          }
          .Homepage_Achive_Achive_YrsExper{
            width: 30vw;
            height: 30vw;
            background-color: #E4E1D8;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            box-shadow: 5px 5px 10px 1px grey;
            z-index: 1;
            .Homepage_Achive_Achive_YrsExper_Img{
              width: 40%;
              height: 55%;
            }
            .Homepage_Achive_Achive_YrsExper_Text{
              width: 80%;
              height: 25%;
              font-family: "Ladyrose";
              text-align: center;
              font-size: 70%;
              line-height: 12px;
            }
          }
          .Homepage_Achive_Achive_Trophies{
            width: 25vw;
            height: 25vw;
            background-color: #E4E1D8;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            box-shadow: 5px 5px 10px 1px grey;
            z-index: 1;
            .Homepage_Achive_Achive_Trophies_Img{
              width: 75%;
              height: 35%;
            }
            .Homepage_Achive_Achive_Trophies_Text{
              width: 100%;
              height: 10%;
              font-family: "Ladyrose";
              text-align: center;
              font-size: 65%;
            }
          }
        }
        .Homepage_Achive_Achive_Details{
          height: 20%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          .Homepage_Achive_Achive_Details_Button{
            height: 20px;
            width: 85px;
            margin-top: 10%;
            font-family: "BancheroBold";
            font-size: 60%;
            background-color: #2d2e2d;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #E4E1D8;
          }
        }
      }
    }
    .Homepage_Gallery{
      position: relative;
      height: 110vh;
      width: 100%;
      background-color:#2d2e2d ;
      padding-bottom: 3%;
      z-index: 0;
      .Homepage_Gallery_Bg{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -2;
      }
      .Homepage_Gallery_Name{
        display: flex;
        justify-content: center;
        align-items: end;
        width: 100%;
        height: 7%;
        color:gray;
        font-family: "CeniosdemoRegular";
        font-size: 120%;
        letter-spacing: 5px;
        z-index:1;
      }
      .Homepage_Gallery_Title{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 6%;
        color:#b38632;
        font-family: "Ladyrose";
        font-size: 180%;
        letter-spacing: 5px;
        z-index: 1;
      }
      .Homepage_Gallery_Text{
        height: 13%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 18px;
        letter-spacing: 0.5px;
        
        .Homepage_Gallery_Text_Content{
          width: 80%;
          color: #E4E1D8;
          font-family: "Medium15";
          font-size: 80%;
          text-align: center;
          z-index: 1;
        }
      }
      .Homepage_Gallery_Img{
        height: 65%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .Homepage_Gallery_Img_Styles{
          width: 90%;
          height: 10%;
          display: grid;
          grid-template-columns: 30% 30% 30%;
          justify-content: center;
          align-items: center;
          margin-bottom: 3%;
          letter-spacing: 1.5px;
          .Homepage_Gallery_Img_Styles_Border{
            width: 100%;
            height: 100%;
            z-index: 3;
            margin-left: 10%;

            .Homepage_Gallery_Img_Styles_Style1,.Homepage_Gallery_Img_Styles_Style2,.Homepage_Gallery_Img_Styles_Style3,.Homepage_Gallery_Img_Styles_Style4,.Homepage_Gallery_Img_Styles_Style5,.Homepage_Gallery_Img_Styles_Style6{
              color: gray;
              font-family: "Ladyrose";
              font-size: 60%;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              z-index: 2;
            }
            .Homepage_Gallery_Img_Styles_Style1{
              width: 100%;
              width: 70%;
              height: 80%;
            }
            .Homepage_Gallery_Img_Styles_Style2{
              width: 80%;
              height: 80%;
            }
            .Homepage_Gallery_Img_Styles_Style3{
              width: 80%;
              height: 80%;
            }
            .Homepage_Gallery_Img_Styles_Style4{
              width: 70%;
              height: 80%;
            }
            .Homepage_Gallery_Img_Styles_Style5{
              width: 90%;
              height: 80%;
            }
            .Homepage_Gallery_Img_Styles_Style6{
              width: 70%;
              height: 80%;
            }
            .active{
              
              border-bottom: 2px solid white;
              color: white;
            }
          }
        }

        .Homepage_Gallery_Img_Container{
          height: 90%;
          width: 80%;
          display: grid;
          grid-template-columns: auto auto auto auto;
          background-color: #2d2e2d;
          overflow-y: auto;
          overflow-x: hidden;
          column-gap: 15px;
          row-gap: 20px;
          font-size: 100%;
          z-index: 4;
          position: relative;

        
        
          .Realism_div_1, .Realism_div_2, .Realism_div_3, .Realism_div_4, .Realism_div_5, .Realism_div_6, .Realism_div_7, .Realism_div_8, .Realism_div_9, .Realism_div_10,.Realism_div_11, .Realism_div_12, .Realism_div_13, .Realism_div_14, .Realism_div_15, .Realism_div_23, .Realism_div_24, .Realism_div_25, .Realism_div_16, .Realism_div_17, .Realism_div_18, .Realism_div_19, .Realism_div_20, .Realism_div_21, .Realism_div_22{
            width: 100%;
            height: 100%;
            .Realism_1,.Realism_8,.Realism_11,.Realism_2, .Realism_3, .Realism_4, .Realism_5, .Realism_6, .Realism_7, .Realism_9, .Realism_10, .Realism_12, .Realism_13, .Realism_14, .Realism_15, .Realism_16, .Realism_17, .Realism_18, .Realism_19, .Realism_20, .Realism_21, .Realism_22, .Realism_23, .Realism_24, .Realism_25{
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
           .Realism_div_2, .Realism_div_3, .Realism_div_4, .Realism_div_5, .Realism_div_6, .Realism_div_7, .Realism_div_9, .Realism_div_10, .Realism_div_12, .Realism_div_13, .Realism_div_14, .Realism_div_15, .Realism_div_23, .Realism_div_24, .Realism_div_25, .Realism_div_16, .Realism_div_17, .Realism_div_19, .Realism_div_20, .Realism_div_22{
            grid-column: span 1;
            grid-row: span 1;

           }
          .Realism_div_1,.Realism_div_8,.Realism_div_11,.Realism_div_18,.Realism_div_21{
            grid-column: span 2;
            grid-row: span 2;

          }
        
          .Portrait_div_1,.Portrait_div_2,.Portrait_div_3,.Portrait_div_4,.Portrait_div_5,.Portrait_div_6,.Portrait_div_7,.Portrait_div_8,.Portrait_div_9,.Portrait_div_10,.Portrait_div_11,.Portrait_div_12,.Portrait_div_13,.Portrait_div_14,.Portrait_div_15,.Portrait_div_16,.Portrait_div_17,.Portrait_div_18,.Portrait_div_19,.Portrait_div_20{
            width: 100%;
            height: 100%;
            .Portrait_1,.Portrait_2,.Portrait_3,.Portrait_4,.Portrait_5,.Portrait_6,.Portrait_7,.Portrait_8,.Portrait_9,.Portrait_10,.Portrait_11,.Portrait_12,.Portrait_13,.Portrait_14,.Portrait_15,.Portrait_16,.Portrait_17,.Portrait_18,.Portrait_19,.Portrait_20{
              width: 100%;
            height: 100%;
            object-fit: cover;
            }

          }
          .Portrait_div_2,.Portrait_div_3,.Portrait_div_4,.Portrait_div_5,.Portrait_div_6,.Portrait_div_7,.Portrait_div_9,.Portrait_div_10,.Portrait_div_12,.Portrait_div_13,.Portrait_div_14,.Portrait_div_15,.Portrait_div_16,.Portrait_div_17,.Portrait_div_19,.Portrait_div_20{
            grid-column: span 1;
            grid-row: span 1;
          }
          .Portrait_div_1,.Portrait_div_8,.Portrait_div_11,.Portrait_div_18{
            grid-column: span 2;
            grid-row: span 2;
          }
          
          .FineLine_div_1,.FineLine_div_2,.FineLine_div_3,.FineLine_div_4,.FineLine_div_5,.FineLine_div_6,.FineLine_div_7,.FineLine_div_8,.FineLine_div_9,.FineLine_div_10,.FineLine_div_11,.FineLine_div_12,.FineLine_div_13,.FineLine_div_14,.FineLine_div_15{
            width: 100%;
            height: 100%;
            .FineLine_1,.FineLine_2,.FineLine_3,.FineLine_4,.FineLine_5,.FineLine_6,.FineLine_7,.FineLine_8,.FineLine_9,.FineLine_10,.FineLine_11,.FineLine_12,.FineLine_13,.FineLine_14,.FineLine_15{
              height: 100%;
              width: 100%;
              object-fit: cover;
            }

          }
          .FineLine_div_2,.FineLine_div_3,.FineLine_div_4,.FineLine_div_5,.FineLine_div_6,.FineLine_div_7,.FineLine_div_9,.FineLine_div_10,.FineLine_div_12,.FineLine_div_13,.FineLine_div_14,.FineLine_div_15{
            grid-column: span 1;
            grid-row: span 1;
          }
          .FineLine_div_1,.FineLine_div_8,.FineLine_div_11{
            grid-column: span 2;
            grid-row: span 2;
          }
 
          .Color_div_1,.Color_div_2,.Color_div_3,.Color_div_4,.Color_div_5,.Color_div_6,.Color_div_7,.Color_div_8,.Color_div_9,.Color_div_10,.Color_div_11,.Color_div_12,.Color_div_13,.Color_div_14,.Color_div_15,.Color_div_16,.Color_div_17,.Color_div_18,.Color_div_19,.Color_div_20,.Color_div_21,.Color_div_22,.Color_div_23,.Color_div_24,.Color_div_25,.Color_div_26,.Color_div_27,.Color_div_28,.Color_div_29,.Color_div_30,.Color_div_31,.Color_div_32,.Color_div_33,.Color_div_34,.Color_div_35{
            width: 100%;
            height: 100%;
            .Color_1,.Color_2,.Color_3,.Color_4,.Color_5,.Color_6,.Color_7,.Color_8,.Color_9,.Color_10,.Color_11,.Color_12,.Color_13,.Color_14,.Color_15,.Color_16,.Color_17,.Color_18,.Color_19,.Color_20,.Color_21,.Color_22,.Color_23,.Color_24,.Color_25,.Color_26,.Color_27,.Color_28,.Color_29,.Color_30,.Color_31,.Color_32,.Color_33,.Color_34,.Color_35{
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

          }
          .Color_div_2,.Color_div_3,.Color_div_4,.Color_div_5,.Color_div_6,.Color_div_7,.Color_div_9,.Color_div_10,.Color_div_12,.Color_div_13,.Color_div_14,.Color_div_15,.Color_div_16,.Color_div_17,.Color_div_19,.Color_div_20,.Color_div_22,.Color_div_23,.Color_div_24,.Color_div_25,.Color_div_26,.Color_div_27,.Color_div_29,.Color_div_30,.Color_div_32,.Color_div_33,.Color_div_34,.Color_div_35{
            grid-column: span 1;
            grid-row: span 1;
          }
          .Color_div_1,.Color_div_8,.Color_div_11,.Color_div_18,.Color_div_21,.Color_div_28,.Color_div_31{
            grid-column: span 2;
            grid-row: span 2;
          }

          .Blackgrey_div_1,.Blackgrey_div_2,.Blackgrey_div_3,.Blackgrey_div_4,.Blackgrey_div_5,.Blackgrey_div_6,.Blackgrey_div_7,.Blackgrey_div_8,.Blackgrey_div_9,.Blackgrey_div_10,.Blackgrey_div_11,.Blackgrey_div_12,.Blackgrey_div_13,.Blackgrey_div_14,.Blackgrey_div_15,.Blackgrey_div_16,.Blackgrey_div_17,.Blackgrey_div_18,.Blackgrey_div_19,.Blackgrey_div_20,.Blackgrey_div_21,.Blackgrey_div_22,.Blackgrey_div_23,.Blackgrey_div_24,.Blackgrey_div_25{
            width: 100%;
            height: 100%;
            .Blackgrey_1,.Blackgrey_2,.Blackgrey_3,.Blackgrey_4,.Blackgrey_5,.Blackgrey_6,.Blackgrey_7,.Blackgrey_8,.Blackgrey_9,.Blackgrey_10,.Blackgrey_11,.Blackgrey_12,.Blackgrey_13,.Blackgrey_14,.Blackgrey_15,.Blackgrey_16,.Blackgrey_17,.Blackgrey_18,.Blackgrey_19,.Blackgrey_20,.Blackgrey_21,.Blackgrey_22,.Blackgrey_23,.Blackgrey_24,.Blackgrey_25{
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

          }
         .Blackgrey_div_2,.Blackgrey_div_3,.Blackgrey_div_4,.Blackgrey_div_5,.Blackgrey_div_6,.Blackgrey_div_7,.Blackgrey_div_9,.Blackgrey_div_10,.Blackgrey_div_12,.Blackgrey_div_13,.Blackgrey_div_14,.Blackgrey_div_15,.Blackgrey_div_16,.Blackgrey_div_17,.Blackgrey_div_19,.Blackgrey_div_20,.Blackgrey_div_22,.Blackgrey_div_23,.Blackgrey_div_24,.Blackgrey_div_25{
          grid-column: span 1;
          grid-row: span 1;
         }
          .Blackgrey_div_1,.Blackgrey_div_8,.Blackgrey_div_11,.Blackgrey_div_18,.Blackgrey_div_21{
            grid-column: span 2;
            grid-row: span 2;
          }
          .Asian_div_1,.Asian_div_2,.Asian_div_3,.Asian_div_4,.Asian_div_5,.Asian_div_6,.Asian_div_7,.Asian_div_8,.Asian_div_9,.Asian_div_10,.Asian_div_11,.Asian_div_12,.Asian_div_13,.Asian_div_14,.Asian_div_15{
            width: 100%;
            height: 100%;
            .Asian_1,.Asian_2,.Asian_3,.Asian_4,.Asian_5,.Asian_6,.Asian_7,.Asian_8,.Asian_9,.Asian_10,.Asian_11,.Asian_12,.Asian_13,.Asian_14,.Asian_15{
              width: 100%;
            height: 100%;
            object-fit: cover;
            }

          }
          .Asian_div_2,.Asian_div_3,.Asian_div_4,.Asian_div_5,.Asian_div_6,.Asian_div_7,.Asian_div_8,.Asian_div_9,.Asian_div_9,.Asian_div_10,.Asian_div_12,.Asian_div_13,.Asian_div_14,.Asian_div_15{
            grid-column: span 1;
          grid-row: span 1;
          }
          .Asian_div_1,.Asian_div_8,.Asian_div_11{
            grid-column: span 2;
            grid-row: span 2;
          }
        }
        
      }
      .Homepage_Gallery_Button{
        width: 100%;
        height: 10%;
        display: flex;
        align-items: center;
        justify-content: center;

        .Homepage_Gallery_Button_Text{
          width: 95px;
          height: 30%;
          background-color: gray;
          font-family: "Ladyrose";
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-size: 70%;
          z-index: 1;
        }
      }
    }
    .Homepage_Seperate3{
      height: 5vh;
      width: 100%;
      position: relative;
      background-color: #8a8989;
      z-index: -1;
      .Homepage_Seperate3_Bg{
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
    .Homepage_Contact{
      position: relative;
      background-color:#2d2e2d ;
      height: 40vh;
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 10%;
      padding-bottom: 10%;
      z-index: -1;
      .Homepage_Contact_Bg{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 0;
      }
      .Homepage_Contact_Title{
        height: 20%;
        font-size: 180%;
        font-family: "Ladyrose";
        color:#b38632;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 7%;
        letter-spacing: 5px;
        z-index: 1;
      }
      .Homepage_Contact_Content{
        height: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        .Homepage_Contact_Content_Text{
          color: #E4E1D8;
          font-family: "Medium15";
          margin-top: 10%;
          letter-spacing: 2px;
          font-size: 80%;
          z-index: 1;
        }
      }
    }
    .Homepage_Seperate4{
      height: 5vh;
      background-color: #2d2e2d;
    }
    .Homepage_Map{
      height: 30vh;
      width: 100%;
      .Homepage_Map_Size{
        height: 100%;
        width: 100%;
        border: none;
      }
    }
    .Homepage_footer{
      height: 25vh;
    }
  }
}