@media screen and (min-width: 992px){
    .Footer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height:100%;
        background-color: #2d2e2d;
        .Footer_Infor{
            height: 20%;
            width: 100%;
            display: flex;
            flex-direction: column;
            font-family: "Ladyrose";
            .Footer_Infor_Icon{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                height: 100%;
                .Footer_Infor_Icon_Link{
                    text-decoration: none;
                    margin-right: 1%;
                    margin-left: 1%;
                    z-index: 3;
                    .Footer_Infor_Icon_Icon{
                        font-size: 200%;
                        text-decoration: none;
                        color: #E4E1D8;
                        z-index: 3;
                    }
                }
                
            }
        }
        .Footer_Link{
            width: 100%;
            height: 30%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            cursor: pointer;
            z-index: 3;
            .Footer_Img{width: 10%;
                height: 100%;
                margin-top: 1%;}                
            .Footer_Page{

                font-family: "Ladyrose";
                font-size: 100%;
                text-decoration: none;
                color: #E4E1D8;
            }
        }
        .Footer_Text{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 10%;
            width: 100%;
            color: white;
            font-family: "Light15";
            letter-spacing: 2px;
        }
    }
}

@media screen and (max-width: 700px) {
    .Footer{
        display: flex;
        flex-direction: row;
        height:100%;
        background-color: #E4E1D8;
        align-items: center;

        .Footer_Infor{
            height: 100%;
            width: 55%;
            display: flex;
            flex-direction: column;
            font-family: "Ladyrose";
            margin-left: 12%;
            letter-spacing: 1.5px;
            .Footer_Infor_Img{
                margin-top: 9%;
                width: 50%;
                height: 25%;
                cursor: pointer;
                .Footer_Infor_Logo{
                    width: 100%;
                    height: 120%;
                }
            }
            .Footer_Infor_IG{
                margin-top: 5%;
                margin-bottom: 10%;
                font-size: 70%;
                height: 5%;
            }
            .Footer_Infor_Phone{
                margin-bottom: 9%;
                font-size: 70%;
                height: 5%;
            }
            .Footer_Infor_Icon{
                display: flex;
                flex-direction: row;
                height: 5%;
                cursor: pointer;
                .Footer_Infor_Icon_Link{
                    text-decoration: none;
                    margin-right: 10%;
                    .Footer_Infor_Icon_Icon{
                        font-size: 120%;
                        margin-right: 10%;
                        text-decoration: none;
                        color: black;
                    }
                }
                
            }
        }
        .Footer_Link{
            width: 20%;
            height: 80%;
            display: flex;
            flex-direction: column;
            align-items: end;
            justify-content: space-evenly;

            .Footer_Page{

                font-family: "Ladyrose";
                font-size: 70%;
                cursor: pointer;
                text-decoration: none;
                color: black;
            }
        }
    }
    
}


